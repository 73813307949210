export default {
    seo: {
      title: 'Финансовая отчетность за 2022 год | Банк 131',
      description: 'Финансовая отчетность за 2022 год'
    },
    title: 'Финансовая отчетность за 2022 год',
    content: [
         {
              label: 'Дата размещения 04.04.2023',
              title: 'Аудиторское заключение о годовой бухгалтерской финансовой отчетности <nobr>за 2022 год.pdf</nobr>',
              link: '/legal/ru/Auditorskoe_zaklyuchenie_za_2022_god.pdf',
         },
         {
              label: 'Дата размещения 15.03.2023',
              title: 'Годовая бухгалтерская финансовая отчетность <nobr>за 2022 год.pdf</nobr>',
              link: '/legal/ru/Godovaya_buhgalterskaya_finansovaya_otchetnost_za_2022_god.pdf',
              clarification: {
                  label: '',
                  text: 'Годовая бухгалтерская (финансовая) отчетность за 2022 год, сформированная с учетом принятых Советом директоров Банка России 29 декабря 2022 года решением «О требованиях к раскрытию кредитными организациями (головными кредитными организациями банковских групп) отчетности и информации в 2023 году».'
              }
         },
         {
              label: 'Дата размещения 15.03.2023',
              title: 'Решение Совета директоров Банка России от 29.12.2022 года.pdf',
              link: '/legal/ru/Reshenie_Soveta_direktorov_Banka_Rossii_ot_29_12_2022_goda.pdf',
         },
     ]
}
